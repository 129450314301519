@import 'assets/styles/mediaQueries';

.reg-card-edit-document {
  display: grid;
  grid-template-columns: 3fr 9fr;
  grid-auto-flow: column;
  column-gap: var(--spacing-lg);

  .reg-card-edit-document-subgrid {
    display: grid;
    grid-template-columns: 6fr 3fr;
    grid-auto-flow: column;
    column-gap: var(--spacing-lg);
    align-items: center;

    &.reg-card-edit-document-removable {
      grid-template-columns: 5fr 3fr min-content;
    }
  }
}
