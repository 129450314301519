.registration-card-edit-wrapper {
  .validation-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .add-new-data-action-button {
    .button-component-icon {
      width: 13px;
      min-width: 13px;
      margin-right: var(--spacing-s);
    }
  }
}
