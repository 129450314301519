.confirm-modal-wrapper {
  .confirm-modal-circular-icon {
    color: var(--primary-color);
  }
  .replace-options-list {
    .radio-button-wrapper {
      align-items: flex-start;
      margin-bottom: var(--spacing-s);
    }
  }
}
