@import 'assets/styles/mediaQueries';

.reg-card-edit-contact-form-section {
  display: grid;
  grid-auto-flow: row;

  .reg-card-edit-contact-form-sub-section-1 {
    grid-row: 1;
  }
  .reg-card-edit-contact-form-sub-section-2 {
    grid-row: 2;
  }
  .reg-card-edit-contact-form-sub-section-3 {
    grid-row: 3;
  }
}
