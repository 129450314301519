@import 'assets/styles/mediaQueries';

.reg-card-edit-phone-sub-form {
  .reg-card-edit-phone-sub-form-fields-wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-flow: column;
    grid-gap: var(--spacing-lg);

    .reg-card-edit-phone-sub-form-type {
      grid-column: span 3;
    }
    .reg-card-edit-phone-sub-form-number {
      grid-column: span 9;
    }
    .reg-card-edit-phone-sub-form-prefix {
      grid-column: span 3;
      & ~ .reg-card-edit-phone-sub-form-number {
        grid-column: span 6;
      }
    }
  }
}
