@import 'assets/styles/mediaQueries';

.reg-card-edit-personal-details {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 1fr;
  column-gap: var(--spacing-lg);

  .reg-card-edit-personal-details-title,
  .reg-card-edit-personal-details-suffix {
    grid-column: span 2;
    & + .reg-card-edit-personal-details-subgrid {
      grid-column: span 10;
    }
  }
  .reg-card-edit-personal-details-subgrid {
    display: grid;
    grid-column: span 12;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    column-gap: var(--spacing-lg);
  }
}
