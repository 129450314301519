@import 'assets/styles/mediaQueries';

.reg-card-edit-address {
  .red-card-edit-address-row-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: var(--spacing-lg);
  }
  .red-card-edit-address-row-subgrid {
    display: grid;
    grid-column: span 9;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    column-gap: var(--spacing-lg);
  }
  .reg-card-edit-address-type {
    grid-column: span 3;
  }
  .reg-card-edit-address-country {
    grid-column: span 3;
  }
  .reg-card-edit-address-district {
    grid-column: span 3;
  }
}
