@import 'assets/styles/mediaQueries';

$marginLeft: -10px;

.reg-card-purpose-of-stay {
  .reg-card-purpose-of-stay-options {
    display: flex;
    flex-direction: column;
    margin: var(--spacing-xs) 0;
    margin-left: $marginLeft;
  }
  .reg-card-purpose-of-stay-options-item {
    display: flex;
  }
}
