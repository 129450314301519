.terms-and-condition-list-wrapper {
  .terms-and-condition-list {
    padding-left: 0;
    margin: 0;
  }
  .terms-and-condition-icon {
    width: var(--spacing-md);
    margin-right: var(--spacing-md);
    fill: var(--body-font-color);
  }
  .terms-and-condition-item {
    display: flex;
    align-items: center;
    margin-top: var(--spacing-xs);
    list-style: none;
  }
}
