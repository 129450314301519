@import 'assets/styles/mediaQueries';

.reg-card-edit-email-sub-form {
  .reg-card-edit-email-sub-form-fields-wrapper {
    display: grid;
    grid-template-columns: 3fr 9fr;
    grid-auto-flow: column;
    grid-gap: var(--spacing-lg);
  }
}
