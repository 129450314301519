@import 'assets/styles/mediaQueries';

.reg-card-personal-section {
  word-break: break-word;

  .reg-card-personal-section-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // stylelint-disable-next-line unit-whitelist
    gap: 1em;
    @include large-screen {
      grid-template-columns: unset;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
    }
    .reg-card-standard-section {
      grid-row: span 2;
      @include large-screen {
        grid-row: span 3;
      }
    }
    .reg-card-wrappable-section {
      grid-row: span 2;
    }
    .reg-card-address-section ~ .reg-card-wrappable-section {
      grid-row: span 1;
    }
  }
  .reg-card-personal-edit-button {
    margin: var(--spacing-md) 0 0 auto;
  }
}
