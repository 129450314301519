.reg-card-summary-section {
  display: flex;

  .reg-card-summary-section-item {
    flex: 1;
    margin-top: var(--spacing-xlg);
  }
}
.reg-card-summary-disclaimer {
  margin: var(--spacing-xxlg) 0;
  text-align: justify;
}
.reg-card-summary-signature {
  height: 280px;
  margin-bottom: var(--spacing-xlg);
}
